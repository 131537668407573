.Snapshot-App {
  text-align: center;
  font-family: fantasy;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  background: #000;
  overflow: hidden;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.Snapshot-header {
  background-color: #000;
  min-height: 15vh;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: orange;
  border-bottom: solid thin orange;
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.snap-welcomeAnimation {
  transform-style: preserve-3d;
  transition: all 1s ease-in;
  animation: snap-welcomeAnimation 1.125s linear;
  -webkit-text-stroke-width: .25px;
  transform: scale(1);
  color: orange;
}

@keyframes snap-welcomeAnimation {
  from {
    color: orange;
    /* -webkit-text-stroke-color: #fff; */
    -webkit-text-stroke-width: .1px;
    transform: scale(.01);
  }

  to {
    color: orange;
    -webkit-text-stroke-color: orange;
    -webkit-text-stroke-width: 0;
    transform: scale(1);
  }
}

.disable-select {
  user-select: none;
}

.glowingMoon {

  transform-style: preserve-3d;
  transition: all 1s ease-in;
  text-shadow: 0 1px 15px, 0 1px 18px orange;
  animation: glowingAnimation infinite 2s ease-in-out;

}

.body {
  border: solid thin orange;
  border-radius: 1em;
  width: 100%;
  background: #000;
  height: 100%;
  max-height: 85vh;
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.25em;
  color: orange;
}

.row {

  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
  transition: all 1s linear;

}

.search-input {
  padding: 1em;
  background: #000;
  border: solid thin #f7931a;
  color: #f7931a;
}

.listContainer {
  
  width: 100%;
  height: 100%;
  background: #000;
  overflow-x: auto;
  
}

.listContainer::-webkit-scrollbar {
  width: 10px;
  /* adjust the scrollbar width */
}

.listContainer::-webkit-scrollbar-thumb {
  background-color: #f7931a; /* set the thumb color */
  border-radius: .125em; /* adjust the thumb shape */
  /* height: 1em; */
}

.listContainer::-webkit-scrollbar-track {
  background-color: transparent; /* set the track color */
}

.listHeader {
  background: #000;
  justify-content: space-around;
}

.listHeaderItem {
  word-break: break-word;
  padding: .75em;
  width: 33%;
  min-width: 33%;
  max-width: 33%;
  border: solid thin orange;
}

.listHeaderItem pre {
  word-break: break-word;
  width: 100%;
  max-inline-size: 100%;
  white-space: pre-wrap;
}

.snapshot-viewer-button:hover {
  cursor: pointer;
  color: #fff;
  transform-style: preserve-3d;
  transition: all 1s ease-in;
  text-shadow: 0 1px 15px, 0 1px 18px orange;
  animation: glowingAnimation infinite 2s ease-in-out;
}

#fileSelectContainer {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  width: 35%;

}

#fileSelect {

  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: .5em;
  border-radius: .5em;
  font-size: 1em;
  word-break: break-word;
  max-width: 50%;
  text-align: center;
  cursor: pointer;

}

#fileSelectLabel {

  display: block;
  font-size: 1.75em;
  cursor: pointer;

}

@keyframes glowingAnimation {
  50% {
    text-shadow: 0px 1px 15px, 0px 1px 27px #fff;
  }
}

@media (max-width: 700px) {

  .body {
    font-size: 1em;
  }

  #fileSelectContainer {

    width: 100%;
  }

}