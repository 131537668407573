.App {
  text-align: center;
  font-family: fantasy;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: orange;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.welcomeAnimation {
  transform-style: preserve-3d;
  transition: all 1s ease-in;
  animation: welcomeAnimation 1.125s linear;
  -webkit-text-stroke-width: .25px;
  transform: scale(2);
  color: orange;
}

@keyframes welcomeAnimation {
  from {
    color: orange;
    /* -webkit-text-stroke-color: #fff; */
    -webkit-text-stroke-width: .1px;
    transform: scale(.01);
  }

  to {
    color: orange;
    -webkit-text-stroke-color: orange;
    -webkit-text-stroke-width: 0;
    transform: scale(2);
  }
}

.disable-select {
  user-select: none;
}

.glowingMoon {

  transform-style: preserve-3d;
  transition: all 1s ease-in;
  text-shadow: 0 1px 15px, 0 1px 18px orange;
  animation: glowingAnimation infinite 2s ease-in-out;

}

@keyframes glowingAnimation {
  50% {
    text-shadow: 0px 1px 15px, 0px 1px 27px #fff;
  }
}