.Portfolio {
  text-align: center;
  background-color: orange;
  opacity: 0.95;
  /* max-height: 10em; */
  overflow: hidden;
  font-family: fantasy;
}

.PortfolioHeader {
  background-color: #000;
  min-height: 15vh;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: orange;
  border-bottom: solid thin orange;
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
}

.portfolioWelcomeAnimation {
  transform-style: preserve-3d;
  transition: all 1s ease-in;
  animation: portfolioWelcomeAnimation 1.125s linear;
  -webkit-text-stroke-width: .25px;
  color: orange;
}

@keyframes portfolioWelcomeAnimation {
  from {
    color: orange;
    -webkit-text-stroke-width: .1px;
    transform: scale(.01);
  }

  to {
    color: orange;
    -webkit-text-stroke-color: orange;
    -webkit-text-stroke-width: 0;
    transform: scale(1);
  }
}

.disable-select {
  user-select: none;
}

.snapshot-viewer-button {
  font-size: .85em;
  margin-left: .5em;
  padding: .5em;
  border: solid thin orange;
  border-radius: .5em;
  background: #000;
  color: orange;
  word-break: break-word;
  text-shadow: 0 1px 15px, 0 1px 18px orange;
}

.snapshot-viewer-button:hover {
  cursor: pointer;
  color: #000;
  border: solid thin #fff;
  animation: none infinite 2s ease-in-out;
  text-shadow: none;
}

.glowingMoon {

  word-wrap: break-word;
  transform-style: preserve-3d;
  transition: all 1s ease-in;
  text-shadow: 0 1px 15px, 0 1px 18px orange;
  animation: glowingAnimation infinite 2s ease-in-out;

}

@keyframes glowingAnimation {
  50% {
    text-shadow: 0px 1px 15px, 0px 1px 27px orange;
  }
}

.portfolioContainer {
  display: flex;
  max-width: 100%;
  text-align: center;
  background-color: orange;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: orange;
}

.portfolioResume {
  margin: 1em;
  margin-top: 1em;
  margin-bottom: 1em;
  padding: 1em;
  border: solid thin orange;
  border-radius: .5em;
  background-color: #000;
}

.limit-text {
  word-break: break-word;
}

.portfolioList {

  border: solid thin orange;
  /* border-top-left-radius: 1em;
  border-top-right-radius: 1em; */
  border-radius: 1em;
  width: 100%;
  background: #000;

}

.portfolioListItem {

  /* width: 100%; */
  border: solid thin orange;
  border-radius: .25em;
  margin: 1.5em;
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.portfolioListItem p {

  width: 100%;
  /* border: solid thin orange;
  border-radius: .125em;
  margin: 1em;
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */

}

.portfolioListItemHeader {

  max-width: 100%;
  margin-bottom: 1em;
  display: block;
  word-break: break-word;

}

.collectionImageContainer {

  display: flex;
  flex-direction: row;
  float: left;
  width: 100%;
  text-align: start;
  height: fit-content;

}

.collectionImageContainer p {

  width: 100%;
  text-align: start;
  padding: 0;
  margin: 0;

}

.column {

  display: flex;
  flex-direction: column;
  float: left;
  width: 100%;
  /* margin-bottom: 1em; */
  text-align: start;
  height: fit-content;

}

.row {

  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
  transition: all 1s linear;

}

.flex-center {
  align-items: center;
  justify-content: center;
}

.select {
  font-size: 1.1em;
  max-width: 100%;
  background: #000;
  color: orange;
  word-break: break-word;
  padding: .125em;
  border: solid thin orange;
  border-radius: .25em;
  margin: .5em;
  padding: .5em;
}

.select:hover {
  background-color: orange;
  color: #000;
  cursor: pointer;
}

.input-checkbox {
  margin: 1.5em;
  accent-color: orange; /* Replace with your desired color */
  transform: scale(2);
  transition: transform .7s linear;
  border-radius: 50%;
  cursor: pointer;
}

.checkbox-container {
  border: solid thin orange;
  border-radius: .25em;
  padding: .25em;
  padding-left: .75em;
}

.input-checkbox:hover {
  accent-color: white;
}

.checkbox-container:hover {
  accent-color: white;
  transform-origin: center center;
  animation: boing .300s infinite ease-in-out;
}

@keyframes boing {
  from {
    transform: scale(1.03);
  }
  to {
    transform: scale(1);
  }
}

.filters-container {  
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: .5em;
  border: solid thin orange;
  margin: 1em;
  padding: 1em;
  transition: all 1s linear;
}

#filters-container-button:hover {
  cursor: pointer;
  color: #000;
  background-color: orange;
}

.filters-list {
  height: 0;
  overflow: hidden;
  transition: all 1s linear;
}

.sort-container {
  
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  border-radius: .5em;
  border: solid thin orange;
  margin: 1em;
  padding: 1em;
}

.sort-container div {
  max-width: 100%;
}

.collectionImageBox {

  display: flex;
  flex-direction: row;
  float: left;
  /* width: 5em; */
  margin-bottom: 1em;
  text-align: start;
  height: fit-content;

}

.collectionImageBox div {
  display: flex;
  flex-direction: row;
}

.collectionImageBox img {
  float: left;
  height: min-content;
  width: 5em;
}

.collectionImageBox a {
  justify-content: center;
}

.collectionImageBox p {
  word-wrap: break-word;
  justify-content: start;
  margin-left: .75em;
}

.borderBottom {
  border-bottom: solid thin orange;
}

/* for-phone-only */
@media (max-width: 599px) {}


/* for-tablet-portrait-up */
@media (min-width: 600px) {}


/* for-tablet-landscape-up */
@media (min-width: 900px) {}


/* for-desktop-up */
@media (min-width: 1200px) {}


/* for-medium-desktop-up */
@media (min-width: 1400px) {}


/* for-big-desktop-up */
@media (max-width: 700px) {
  .collectionImageBox div {
    display: flex;
    flex-direction: row;
  }

  .sort-container {
    flex-direction: column;
  }

  .filters-container {
    flex-wrap: wrap;
    flex-direction: row;
  }
  
  .checkbox-container {
    border: solid thin orange;
    border-radius: .25em;
    margin: .5em;
    padding: .25em;
  }
}